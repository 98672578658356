import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import {
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import ItemCard, {
  ResponsiveTitleContentCard,
} from "../components/ResponsiveItemCard.jsx";
import { graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query {
    keystone {
      allLinkCategories(sortBy: name_ASC) {
        name
        links(sortBy: title_ASC) {
          title
          link
          description
          coverImage {
            publicUrl
          }
        }
      }
    }
  }
`;

const DoctorsPageTemplate = ({ data }) => {
  const categories = data.keystone.allLinkCategories;

  return (
    <Fragment>
      <Helmet>
        <title>Link Utili - Poliambulatorio Giano</title>
        <meta name="description" content="Link utili per la tua salute" />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">Link Utili</Text>
      </TitleBox>
      <Grid container>
        {categories.map((x, i) => (
          <GridItemCardList colorNumber={i} title={x.name} key={i}>
            {x.links.map((l, j) => (
              <CardListContainer
                queryText={l.name + " " + l.description}
                key={j}
                navigate={l.link}
              >
                {l.description && (
                  <ResponsiveTitleContentCard
                    title={l.title}
                    image={l.coverImage ? l.coverImage.publicUrl : undefined}
                    content={l.description}
                  />
                )}
                {!l.description && (
                  <ItemCard
                    title={l.title}
                    image={l.coverImage ? l.coverImage.publicUrl : undefined}
                  />
                )}
              </CardListContainer>
            ))}
          </GridItemCardList>
        ))}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default DoctorsPageTemplate;
